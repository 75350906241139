// libs
import { log } from 'shared/helpers/newRelic';
import { memo, useEffect } from 'react';
// helpers
import { TAG_IDENTIFIER } from 'helpers/SentryLogger/constants';
import { initialize } from 'shared/components/VisitorPriortization/helpers/initializeApp';

//hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// constants
const VISITOR_PRIORTIZATION = 'visitor priortization';

function VisitorPriortization() {
  const config = useRemoteConfig('visitorPriortization');
  useEffect(() => {
    // converting remote config to object in useEffect
    // avoiding in mapStateToProp func as firebaseconfig is object on any
    // redux change would give new object causing re-render
    const { enabled, firebaseCollection, firebaseConfig, firebaseDoc } = config;
    if (
      enabled === false ||
      firebaseConfig === undefined ||
      firebaseCollection === undefined ||
      firebaseDoc === undefined
    ) {
      return;
    }
    //initializing firebase app
    try {
      initialize(firebaseConfig, firebaseCollection, firebaseDoc);
    } catch (_) {
      log({
        errTitle: 'Error in firebase initialisation',
        tags: { [TAG_IDENTIFIER.MODULE]: VISITOR_PRIORTIZATION },
      });
    }
  }, [config]);

  return null;
}

export default memo(VisitorPriortization);
