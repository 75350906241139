// libs
import isEmpty from 'lodash/isEmpty';
// helpers
import Storage from 'shared/helpers/storage';
import {
  getRandomNumber,
  getCachedVPDoc,
} from 'shared/components/VisitorPriortization/helpers/helpers';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

//constants
import { VP_DOC_KEY, VP_USER } from 'shared/components/VisitorPriortization/constants';

/** reference to firebase cofig */
let firebaseConfig: VP.Config | undefined;
/** firestore collection name */
let firebaseCollection: string | undefined;
/** firestore document name */
let firebaseDoc: string | undefined;
/** user segment / bucket */
let userSegment = 0;
/** firestore visitor priortization config */
let visitorPriortizationConfig: VP.Doc | undefined = __BROWSER__ ? getCachedVPDoc() : undefined;

const setCachedVPDoc = (doc: VP.Doc) => {
  window.sessionStorage.setItem(VP_DOC_KEY, JSON.stringify(doc));
};

const setUserCookie = () => {
  if (visitorPriortizationConfig === undefined) {
    return;
  }

  const storedInfo = Storage.getItem(VP_USER, '') || '';

  let cachedUserInfo = {
    ruleID: undefined,
    key: undefined,
  };

  if (isEmpty(storedInfo) === false) {
    try {
      cachedUserInfo = JSON.parse(storedInfo);
    } catch (e) {
      /** DO NOTHING */
    }
  }

  userSegment = cachedUserInfo.key || 0;
  // if storage ruleID same as firestore ruleID dont do anything
  if (cachedUserInfo.ruleID === visitorPriortizationConfig.rule_id) {
    return;
  }

  userSegment = getRandomNumber();
  const info = JSON.stringify({
    key: userSegment,
    ruleID: visitorPriortizationConfig.rule_id,
  });

  Storage.setItem(VP_USER, info);
};

const listenDocChanges = (app: any) => {
  const db = getFirestore(app);
  if (!db || firebaseCollection === undefined || firebaseDoc === undefined) {
    throw new Error('Firebase Config, collection and doc undefined');
  }

  visitorPriortizationConfig = getCachedVPDoc();
  setUserCookie();

  const visitorPrioritizationCollection = collection(db, firebaseCollection);

  getDocs(visitorPrioritizationCollection).then((documents) => {
    visitorPriortizationConfig = documents.docs[0].data() as VP.Doc;
    setUserCookie();
    setCachedVPDoc(visitorPriortizationConfig);
  });
};

export const initialize = (config: VP.Config, fbcollection: string, doc: string) => {
  firebaseConfig = { ...config };
  firebaseCollection = fbcollection;
  firebaseDoc = doc;

  const app = initializeApp(firebaseConfig);
  listenDocChanges(app);
};
